import { UserTier } from "api-shared";
import { useCurrentClient } from "./client";
import { isActiveRegisteredOrInvitedUser, useUsersQuery } from "./users";

export type SeatLimit = {
    hasSeatLimit: boolean;
    hasOpenSeats: boolean;
    seats: number;
    maxSeats: number;
    openSeats: number;
};

export type SeatLimits = {
    hasSeatLimit: boolean;
    [UserTier.Admin]: SeatLimit;
    [UserTier.Advanced]: SeatLimit;
    [UserTier.Basic]: SeatLimit;
    [UserTier.Light]: SeatLimit;
};

export const buildSeatLimit = (seats: number, optionalMaxSeats: number | null): SeatLimit => {
    const maxSeats = optionalMaxSeats ?? Infinity;

    return {
        hasSeatLimit: optionalMaxSeats !== null,
        hasOpenSeats: maxSeats > seats,
        seats,
        maxSeats: maxSeats,
        openSeats: maxSeats - seats,
    };
};

export const useSeatLimits = (): SeatLimits => {
    const {
        adminUserSeats: maxAdminSeats,
        advancedUserSeats: maxAdvancedSeats,
        basicUserSeats: maxBasicSeats,
        lightUserSeats: maxLightSeats,
    } = useCurrentClient();

    const usersQuery = useUsersQuery();
    const allUsers = usersQuery.data ?? [];

    const users = allUsers.filter(isActiveRegisteredOrInvitedUser).filter((u) => !u.email?.includes("@valuedesk.de"));

    const adminSeats = users.filter((u) => u.tier === UserTier.Admin).length;
    const advancedSeats = users.filter((u) => u.tier === UserTier.Advanced).length;
    const basicSeats = users.filter((u) => u.tier === UserTier.Basic).length;
    const lightSeats = users.filter((u) => u.tier === UserTier.Light).length;

    const admin = buildSeatLimit(adminSeats, maxAdminSeats);
    const advanced = buildSeatLimit(advancedSeats, maxAdvancedSeats);
    const basic = buildSeatLimit(basicSeats, maxBasicSeats);
    const light = buildSeatLimit(lightSeats, maxLightSeats);

    return {
        hasSeatLimit: admin.hasSeatLimit || advanced.hasSeatLimit || basic.hasSeatLimit || light.hasSeatLimit,
        admin,
        advanced,
        basic,
        light,
    };
};
