import GroupRounded from "@mui/icons-material/GroupRounded";
import { Avatar, AvatarProps } from "@mui/material";
import React from "react";

export interface IGroupAvatarProps extends AvatarProps {
    size?: number;
}
const GroupAvatar = React.forwardRef<any, IGroupAvatarProps>(({ size, style, children, ...other }, ref) => {
    const combinedStyle =
        size != null
            ? {
                  width: size,
                  height: size,
                  fontSize: size / 1.5, // make sure that font does not exceed circle
                  lineHeight: "normal",
                  ...style,
              }
            : style;

    return (
        <Avatar ref={ref} alt="G" style={combinedStyle} {...other}>
            <GroupRounded fontSize="inherit" />
        </Avatar>
    );
});

export default GroupAvatar;
