import { Stack, Typography, List, ListItemAvatar, Popover } from "@mui/material";
import { translationKeys } from "../../translations/main-translations.ts";
import SearchInput from "../input/SearchInput.tsx";
import UserEntry from "../user/UserEntry.tsx";
import { useState } from "react";
import { useDebounce } from "../../hooks/useDebounce.ts";
import { compareUsersByDisplayName } from "../../lib/sort.ts";
import { useTranslation } from "react-i18next";
import { UserListDto } from "api-shared";

interface GroupDetailsPopoverProps {
    groupId: number;
    groupUsers: UserListDto;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const GroupDetailsPopover = ({ groupId, groupUsers, anchorEl, onClose }: GroupDetailsPopoverProps) => {
    const { t } = useTranslation();
    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey.toLocaleLowerCase());
    const open = Boolean(anchorEl);
    const popoverId = open ? `group-details-popover-${groupId}` : undefined;

    const filteredUsers = groupUsers
        .filter(({ displayname }) => displayname?.toLowerCase().includes(debouncedSearchKey.toLowerCase()))
        .toSorted(compareUsersByDisplayName);

    return (
        <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <Stack px={2} pt={2}>
                <Stack spacing={1}>
                    <Typography fontWeight="medium">{t(translationKeys.VDLANG_USERS, { count: groupUsers.length })}</Typography>
                    {groupUsers.length > 0 ? <SearchInput translate={t} onChange={setSearchKey} searchKey={searchKey} /> : null}
                </Stack>
                {filteredUsers.length > 0 ? (
                    <List
                        dense
                        sx={(theme) => ({
                            maxHeight: theme.spacing(26),
                            overflowY: "auto",
                        })}
                    >
                        {filteredUsers.map((user) => (
                            <ListItemAvatar
                                key={user.id}
                                sx={{
                                    pr: 1,
                                    textOverflow: "ellipsis",
                                    maxWidth: 320,
                                }}
                            >
                                <UserEntry translate={t} avatarProps={{ size: 24 }} user={user} disableGutters />
                            </ListItemAvatar>
                        ))}
                    </List>
                ) : (
                    <Typography py={2}>{t(translationKeys.VDLANG_PERMISSION_DIALOG_NO_USERS_FOUND)}</Typography>
                )}
            </Stack>
        </Popover>
    );
};
